import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Box } from '@material-ui/core'
import { H1, H3, H5, H6 } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import { monochrome } from '@helpers/palette'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    textAlign: (props) => props.textAlignment || 'center',
    color: theme.palette.text.tertiary,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightLight,
    display: 'block',
    paddingTop: '1rem',
  },
  bigRoomCTA: {
    textAlign: 'center',
    display: 'block',
  },

  subtitle: {
    color: (props) =>
      props.variant === 'light-blue'
        ? theme.palette.secondary.main
        : (props.subtitleColor &&
            props.subtitleColor.color &&
            monochrome(props.subtitleColor.color)) ||
          theme.palette.secondary.main,
    width: ({ subtitleProportion }) =>
      !!subtitleProportion && `${subtitleProportion}%`,
    textAlign: (props) => props.textAlignment || 'center',
    [theme.breakpoints.up('sm')]: {},
    display: 'block',
    marginBottom: '0.5rem',
  },
  description: ({ textAlignment, descriptionProportion }) => ({
    textAlign: textAlignment || 'center',
    color: theme.palette.text.primary,
    margin: !textAlignment || textAlignment === 'center' ? 'auto' : 'initial',
    width: !!descriptionProportion && `${descriptionProportion}%`,
    '& p': {
      textAlign: textAlignment || 'center',
      display: 'flex',
      lineHeight: '150%',
    },
    display: 'block',
  }),
  flexBox: {
    display: 'flex',
    paddingTop: '40px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '44px',
    height: '42px',
  },
  iconText: {
    color: theme.palette.text.primary,
    display: 'inline',
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: '10px',
    fontSize: '1.5rem',
  },
}))

const TitleSubtitleDescriptionModule = (props) => {
  const {
    title,
    subtitle,
    description,
    cta,
    body,
    smallTitle,
    icon,
    iconText,
    iconTextIsH1,
    shouldRenderH1,
    bgColor,
  } = props.blok

  const classes = useStyles(props.blok)

  const background = !!bgColor && bgColor.color
  return (
    <SbEditable content={props.blok}>
      <PageContainer backgroundColor={background}>
        <ContentContainer>
          <Box className={classes.contentBox}>
            {(!!icon?.length || iconText || title) && (
              <Box my={2}>
                {(!!icon?.length || iconText) && (
                  <Box className={classes.flexBox}>
                    {!!icon?.length && (
                      <Box className={classes.icon}>{renderBloks(icon)}</Box>
                    )}
                    {iconText && (
                      <H6
                        className={classes.iconText}
                        component={iconTextIsH1 && shouldRenderH1 ? 'h1' : 'h6'}
                      >
                        {iconText.toUpperCase()}
                      </H6>
                    )}
                  </Box>
                )}
                {title &&
                  (smallTitle ? (
                    <H5
                      component={!iconTextIsH1 && shouldRenderH1 ? 'h1' : 'h3'}
                      className={classes.title}
                    >
                      {title}
                    </H5>
                  ) : (
                    <H1
                      component={!iconTextIsH1 && shouldRenderH1 ? 'h1' : 'h3'}
                      className={classes.title}
                    >
                      {title}
                    </H1>
                  ))}
              </Box>
            )}
            {subtitle && (
              <H3
                component={
                  !iconTextIsH1 && shouldRenderH1 && !title ? 'h1' : 'h3'
                }
                className={classes.subtitle}
              >
                {subtitle}
              </H3>
            )}
            {description && description.length > 0 && (
              <Box className={classes.description}>
                {renderBloks(description)}
              </Box>
            )}
            {!!cta.length && (
              <Box my={1} className={classes.bigRoomCTA}>
                {renderBloks(cta)}
              </Box>
            )}
            {body && renderBloks(body)}
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default TitleSubtitleDescriptionModule
